<template>
	<MapBlock
		:zoom="zoom"
		:markers="markers"
		:fitness="fitness"
		style="width: 100%; height: 100%; position: relative"
	></MapBlock>
</template>

<script>
import MapBlock from "@/components/MapBlock.vue";

export default {
	components: {
		MapBlock,
	},
	computed: {
		markers: function () {
			let data = [];
			for (var i = 0; i < this.$store.state.summary.length; i++) {
				let item = this.$store.state.summary[i];
				let element = {
					id: item.id,
					lat: item.position.lat,
					lng: item.position.lng,
					icon: {
						color: this.$store.getters.getAlertColor(item),
					},
				};
				data.push(element);
			}
			return data;
		},
	},
	data() {
		return {
			zoom: 17,
			fitness: true,
		};
	},
};
</script>
